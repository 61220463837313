import React from 'react';
import Logo from 'components/Logo';
import { Background, Container, ContentWrapper, } from './index.styles';
import EnvBanner from 'components/EnvBanner';
import { isProduction } from 'utils/env';

type Props = {
  setComponentOnDemand: (component: string, options: any) => void;
};

const Box: React.FC<Props> = ({ children, setComponentOnDemand }) => {
  return (
    <Background>
      {!isProduction && <EnvBanner />}
      <Container>
        <Logo />
        <ContentWrapper>{children}</ContentWrapper>
      </Container>
    </Background>
  );
};

export default Box;
